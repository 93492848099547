import React from 'react';
import { Trans } from '@lingui/macro';
import { useModalContext } from '../../../hooks/useModal';
import { CollateralType } from '../../../helpers/types';
import { AlertBanner } from '../../../ui-kit/AlertBanner';
import { ScrollModalContent, ScrollModalFooter } from '../../primitives/ScrollModal';
import {
  DetailsCollateralLine,
  DetailsNumberLine,
  TxModalDetails
} from '../FlowCommons/TxModalDetails';
import { TxPositionDetails } from '../FlowCommons/TxPositionDetails';
import { TxSuccessView } from '../FlowCommons/Success';
import { PositionModalWrapperProps } from '../FlowCommons/PositionModalWrapper';
import { SupplyPositionActions } from './SupplyPositionActions';

export const SupplyPositionModalContent = React.memo(
  ({ isWrongNetwork, enrichedPosition, tokenId }: PositionModalWrapperProps) => {
    const { gasLimit, txError, mainTxState, setTxError } = useModalContext();

    const supplyPositionActionsProps = {
      marketId: enrichedPosition.marketId,
      isWrongNetwork,
      tokenId,
    }

    // const addToken: ERC20TokenType = {
    //   address: poolReserve.yTokenAddress,
    //   symbol: poolReserve.iconSymbol,
    //   decimals: poolReserve.decimals,
    //   aToken: true,
    // };

    if (mainTxState.success)
      return (
        <ScrollModalContent>
          <TxSuccessView
            action={<Trans>supplied position</Trans>}
            amount={enrichedPosition.marketReferenceCurrencyValue.toString()}
            symbol={`${enrichedPosition.token0.symbol} / ${enrichedPosition.token1.symbol}`}
            // addToken={addToken}
          />
        </ScrollModalContent>
      );

    return enrichedPosition ? (
      <>
        <ScrollModalContent>
          <TxPositionDetails token0={enrichedPosition.token0} token1={enrichedPosition.token1} />
          <TxModalDetails gasLimit={gasLimit} skipLoad={true}>
            <DetailsNumberLine
              description={<Trans>Total liquidity to supply</Trans>}
              value={enrichedPosition.marketReferenceCurrencyValue}
              numberPrefix='$'
            />
            <DetailsNumberLine
              description={<Trans>Fees earned</Trans>}
              value={
                enrichedPosition.token0.marketReferenceCurrencyFee + enrichedPosition.token1.marketReferenceCurrencyFee
              }
              numberPrefix="$"
            />
            <DetailsCollateralLine collateralType={CollateralType.ENABLED} />
          </TxModalDetails>

          {txError && (
            <AlertBanner variant="error" txError={txError} onClose={() => setTxError(undefined)} />
          )}

        </ScrollModalContent>

        <ScrollModalFooter>
          <SupplyPositionActions {...supplyPositionActionsProps} />
        </ScrollModalFooter>
      </>
    ) : null;
  }
);
