import { Box, Typography } from '@mui/material';
import { Trans } from '@lingui/macro';
import React from 'react';
import { IPositionToken } from '../../../types/uniswapTokens';
import { TokenIcon } from '../../primitives/TokenIcon';

export interface ITxPositionDetailsProps {
  token0: IPositionToken;
  token1: IPositionToken;
}

export const TxPositionDetails = ({ token0, token1 }: ITxPositionDetailsProps) => (
  <Box sx={{ pt: 5 }}>
    <Typography sx={{ mb: 1 }} color="text.secondary">
      <Trans>Position Liquidity</Trans>
    </Typography>

    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        p: 3,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '8px 8px 0 0',
        '.MuiBox-root:last-of-type': {
          mb: 0,
        },
      })}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TokenIcon symbol={token0.iconSymbol} fontSize="large" />
        <Box sx={{ pl: 2, overflow: 'hidden' }}>
          <Typography variant="h4" noWrap>
            {token0.name}
          </Typography>

          <Box sx={{ p: { xs: '0', xsm: '2px 0px' } }}>
            <Typography variant="subheader2" color="text.muted" noWrap>
              {token0.symbol}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <Typography variant="h4" noWrap>
          {Math.round(token0.liquidity * 10000) / 10000}
        </Typography>
        <Typography variant="subheader2" color="text.muted" noWrap>
          ${Math.round(token0.marketReferenceCurrencyValue * 100) / 100}
        </Typography>
      </Box>
    </Box>
    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        p: 3,
        border: `1px solid ${theme.palette.divider}`,
        borderTop: 'unset',
        borderRadius: '0 0 8px 8px',
        '.MuiBox-root:last-of-type': {
          mb: 0,
        },
      })}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TokenIcon symbol={token1.iconSymbol} fontSize="large" />
        <Box sx={{ pl: 2, overflow: 'hidden' }}>
          <Typography variant="h4" noWrap>
            {token1.name}
          </Typography>

          <Box
            sx={{
              p: { xs: '0', xsm: '2px 0px' },
            }}
          >
            <Typography variant="subheader2" color="text.muted" noWrap>
              {token1.symbol}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <Typography variant="h4" noWrap>
          {Math.round(token1.liquidity * 10000) / 10000}
        </Typography>
        <Typography variant="subheader2" color="text.muted" noWrap>
          ${Math.round(token1.marketReferenceCurrencyValue * 100) / 100}
        </Typography>
      </Box>
    </Box>
  </Box>
)
